$(document).ready(function() {
    var $window = $(window);
    /**
     * For the navigation toggle
     *
     */

    $(".menu-toggle a").click(function(e){
        e.preventDefault();

        $(".menu-container > nav").slideToggle();
        $(".menu-container").toggleClass("menu-down");
        $(".menu-toggle").toggleClass("active");
    });

    /**
     * Reset styles when window is resized
     */
    $window.resize(function() {
        if($( window ).width() >= "1024") {
            $(".menu-container > nav").css("display", "block");

            if($(".menu").hasClass("menu-down")) {
                $(".menu").toggleClass("menu-up");
            }
        }
        else {
            $(".menu-container > nav").css("display", "none");
        }
    });

    /**
     * For toggling sections in the home page and scrolling into view
     */

    $(document).on('click', '.js-section-toggle > li', function() {
        // Bring the related section into view and hide the active section
        var targetId = $(this).data("target-id");
        var target = $('#' + targetId);
        target.siblings().hide();
        target.show();

        // Select the button
        $(this).siblings().removeClass("active");
        $(this).addClass("active");

        /// Scroll to content
        if($('.options-description-container').length) {
            event.preventDefault();
            var height = $('.options-description-container').height();
            // height += $('.home-spread-options').height();
            $('html, body').stop().animate({
                scrollTop: $('.options-description-container').position().top - height
            }, 1000);
        }

        // Activate the dots
        $('.home-spread-options-balls').find('li').removeClass('active');
        $('.home-spread-options-balls').find('li[data-target-id=' + targetId + ']').addClass('active');
    });


    // Map related logic
    // Cache reference to window and animation items
    var $animationElementBorder = $('.js-circle-container');

    $window.scroll(function() {
        checkIfInView($animationElementBorder);
    });
    $window.trigger('scroll');

    function checkIfInView() {
        var $animationElement = $('.circle-container');
        var windowHeight = $window.height();
        var windowTopPosition = $window.scrollTop();
        var windowBottomPosition = (windowTopPosition + windowHeight);

        $.each($animationElement, function() {
            var $element = $(this);
            var elementHeight = $element.outerHeight();
            var elementTopPosition = $element.offset().top;
            var elementBottomPosition = (elementTopPosition + elementHeight);

            //check to see if this current container is within viewport
            if ((elementBottomPosition >= windowTopPosition) &&
                (elementTopPosition <= windowBottomPosition)) {
                $element.addClass('active');
                $(this).removeClass('unclicked');
            }
        });
    }
});
